var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      !_vm.imageUrl
        ? _c(
            "a-upload",
            {
              attrs: {
                "list-type": "picture-card",
                accept: "image/*",
                "show-upload-list": false,
                action: _vm.upload
              }
            },
            [
              _c(
                "a-button",
                {
                  attrs: { icon: "upload", type: "link", loading: _vm.loading }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_upload")) + " ")]
              )
            ],
            1
          )
        : _c(
            "a-space",
            { attrs: { direction: "horizontal" } },
            [
              _c("figure", [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: _vm.imageUrl, alt: "part image" }
                })
              ]),
              _c("a-button", {
                attrs: { icon: "delete", type: "danger" },
                on: { click: _vm.remove }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }